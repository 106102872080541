export default {
  namespaced: true,

  state: {
    isNotified: false,
    content: [],
    color: null,
  },

  mutations: {
    SHOW_SNACKBAR(state, payload) {
      state.content = payload.message;
      state.color = payload.type;
      state.isNotified = true;
    },
    CLEAR_SNACKBAR(state) {
      state.isNotified = false;
      state.content = [];
      state.color = '';
    },
  },

  actions: {
    showSnackbar({ commit }, payload) {
      commit('SHOW_SNACKBAR', payload);
    },
    clearSnackbar({ commit }) {
      commit('CLEAR_SNACKBAR');
    },
  },
};
