import resourcesApi from '@/api/v1/resources';

export default {
  namespaced: true,

  state: {
    places: {},
    trains: {},
    wagons: {},
    quicks: {},
    currentTrainUid: null,
    currentQuickId: null,
    currentQuickUid: null,
    loading: false,
  },

  mutations: {
    SET_PLACES_FROM_API(state, places) {
      state.places = places;
    },
    SET_TRAINS_FROM_API(state, trains) {
      state.trains = trains;
    },
    SET_WAGONS_FROM_API(state, wagons) {
      state.wagons = wagons;
    },
    SET_QUICKS_FROM_API(state, quicks) {
      state.quicks = quicks.sort((a, b) => b.id - a.id);
    },
    SET_CURRENT_TRAIN_UID(state, trainUid) {
      state.currentTrainUid = trainUid;
    },
    SET_CURRENT_QUICK_ID(state, quickId) {
      state.currentQuickId = quickId;
    },
    SET_CURRENT_QUICK_UID(state, quickUid) {
      state.currentQuickUid = quickUid;
    },
    CLEAR_STATE(state) {
      state.wagons = {};
    },
    SET_LOADING_STATUS(state, loading) {
      state.loading = loading;
    },
  },

  actions: {
    getPlacesFromApi({ commit, dispatch }) {
      resourcesApi.getPlaces()
        .then((resp) => {
          commit('SET_PLACES_FROM_API', resp.data);
          dispatch('snackbar/showSnackbar', {
            message: ['Places loaded'],
            type: 'success',
          }, { root: true });
        })
        .catch((err) => {
          console.error('ERR: ', err);
        });
    },

    getTrainsFromApi({ commit, dispatch }, placeId) {
      resourcesApi.getTrainsByPlaceId(placeId)
        .then((resp) => {
          commit('SET_TRAINS_FROM_API', resp.data);
          dispatch('snackbar/showSnackbar', {
            message: ['Trains loaded'],
            type: 'success',
          }, { root: true });
        })
        .catch((err) => {
          console.error('ERR: ', err);
        });
    },

    getWagonsFromApi({ commit, dispatch }, data) {
      commit('CLEAR_STATE');
      commit('SET_LOADING_STATUS', true);
      resourcesApi.getTrainEventList(2, data.placeId, data.trainId)
        .then((resp) => {
          commit('SET_LOADING_STATUS', false);
          commit('SET_WAGONS_FROM_API', resp.data);
          dispatch('snackbar/showSnackbar', {
            message: ['Wagons loaded'],
            type: 'success',
          }, { root: true });
        })
        .catch((err) => {
          commit('SET_LOADING_STATUS', false);
          console.error('ERR: ', err);
        });
    },

    setCurrentTrainUid({ commit }, trainUid) {
      commit('SET_CURRENT_TRAIN_UID', trainUid);
    },

    setCurrentQuickId({ commit }, quickId) {
      commit('SET_CURRENT_QUICK_ID', quickId);
    },

    setCurrentQuickUid({ commit }, quickUid) {
      commit('SET_CURRENT_QUICK_UID', quickUid);
    },

    getQuicksFromApi({ commit, dispatch }) {
      resourcesApi.getQuickList()
        .then((resp) => {
          commit('SET_QUICKS_FROM_API', resp.data);
          dispatch('snackbar/showSnackbar', {
            message: ['Quicks loaded'],
            type: 'success',
          }, { root: true });
        })
        .catch((err) => {
          console.error('ERR: ', err);
        });
    },

    addNewQuickByApi({ dispatch }, formsData) {
      return resourcesApi.addNewQuick(formsData.quick)
        .then((resp) => {
          dispatch('getQuicksFromApi');
          dispatch('uploadQuickPhoto', {
            fileType: 'image',
            objectId: resp.data.id,
            type: 'original',
            file: formsData.file,
          });
          dispatch('snackbar/showSnackbar', {
            message: ['Quick add'],
            type: 'success',
          }, { root: true });
        }).catch((err) => {
          console.error('ERR: ', err);
        });
    },

    uploadQuickPhoto({ dispatch }, fileData) {
      resourcesApi.addNewQuickImage(fileData)
        .then((resp) => {
          console.log('RESP: ', resp.data);
          dispatch('snackbar/showSnackbar', {
            message: ['Quick photo upload'],
            type: 'success',
          }, { root: true });
        }).catch((err) => {
          console.error('ERR: ', err);
        });
    },

    getQuickImageFromApi({ dispatch }, getParams) {
      return resourcesApi.getQuickImage(getParams.quickId, getParams.imageType)
        .then((resp) => {
          dispatch('snackbar/showSnackbar', {
            message: ['Quick photo loaded'],
            type: 'success',
          }, { root: true });
          return resp;
        }).catch((err) => {
          console.error('ERR: ', err);
        });
    },
  },
};
