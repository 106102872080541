<template>
  <v-app>
    <router-view/>
    <TheSnackbar/>
  </v-app>
</template>

<script>
import TheSnackbar from './components/TheSnackbar.vue';

export default {
  name: 'App',
  components: { TheSnackbar },
};
</script>

<style lang="scss">
@import "~@/assets/styles/global.scss";

html {
  background: url('~@/assets/img/graff_on_trainn.jpg') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.blur-content {
  filter: blur(16px);
}
</style>
