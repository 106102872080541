<template>
  <v-snackbar
    v-model="show"
    :color="color"
    timeout="5000"
    :top="$vuetify.breakpoint.smAndDown"
    :bottom="$vuetify.breakpoint.mdAndUp"
  >
    <div
      class="text-center"
      v-for="(message, index) in content"
      :key="index"
    >
      {{ message }}
    </div>
  </v-snackbar>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  computed: {
    ...mapState('snackbar', [
      'isNotified',
      'color',
      'content',
    ]),

    show: {
      get() {
        return this.isNotified;
      },
      set() {
        setTimeout(this.clearSnackbar, 300);
      },
    },
  },

  methods: {
    ...mapActions('snackbar', [
      'clearSnackbar',
    ]),
  },
};
</script>
