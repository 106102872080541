import Vue from 'vue';
import Vuex from 'vuex';
import { injectAuthInterceptor } from '../api/axios';
import auth from './modules/auth';
import resources from './modules/resources';
import snackbar from './modules/snackbar';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    auth,
    resources,
    snackbar,
  },
});

injectAuthInterceptor(
  () => {
    store.dispatch('auth/logoutUser');
  },
);

export default store;
