import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: '/quicks',
  },
  {
    path: '/places',
    name: 'places',
    component: () => import('../views/Page/PlacesPage.vue'),
  },
  {
    path: '/trains',
    name: 'trains',
    component: () => import('../views/Page/TrainsPage.vue'),
  },
  {
    path: '/trains/:placeId',
    name: 'trainsById',
    component: () => import('../views/Page/TrainsPage.vue'),
  },
  {
    path: '/wagons',
    name: 'wagons',
    component: () => import('../views/Page/WagonsPage.vue'),
  },
  {
    path: '/wagons/:placeId/train/:trainId/:trainUid',
    name: 'wagonsById',
    component: () => import('../views/Page/WagonsPage.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Auth/LoginPage.vue'),
  },
  {
    path: '/quicks',
    name: 'quicks',
    component: () => import('../views/Page/QuicksPage.vue'),
  },
  {
    path: '/quicks/:quickId',
    name: 'quickById',
    component: () => import('../views/Page/QuickPage.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
