import axios from 'axios';

export const axiosApi = axios.create({
  baseURL: `${process.env.VUE_APP_API_BASE_URL}`,
  withCredentials: true,
});

export const axiosStorageApi = axios.create({
  baseURL: `${process.env.VUE_APP_STORAGE_URL}`,
  withCredentials: true,
});

export function injectAuthInterceptor(logoutCallback) {
  axiosApi.interceptors.response.use(undefined, (error) => {
    if (error.response.status === 401) {
      logoutCallback();
    } else {
      throw error;
    }
  });
}
