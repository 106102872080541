import authApi from '@/api/v1/auth';
import router from '@/router/index';

export default {
  namespaced: true,

  state: {
    userLoggedIn: false,
    user: {},
  },

  mutations: {
    SET_USER_STATUS(state, status) {
      state.userLoggedIn = status;
    },
    SET_USER_DATA(state, data) {
      state.user = data;
    },
  },
  actions: {
    loginUser({ commit, dispatch }, user) {
      authApi.login(user)
        .then(() => {
          commit('SET_USER_STATUS', true);
          commit('SET_USER_DATA', { email: user.email });
          dispatch('snackbar/showSnackbar', {
            message: ['Sign in success'],
            type: 'success',
          }, { root: true });
          router.push({ name: 'places' });
        })
        .catch(() => {
          commit('SET_USER_STATUS', false);
        });
    },

    logoutUser({ dispatch }) {
      dispatch('snackbar/showSnackbar', {
        message: ['Sign out succesed'],
        type: 'success',
      }, { root: true });
      setTimeout(() => {
        router.push({ name: 'login' });
      }, 1000);
    },
  },
};
