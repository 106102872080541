import { axiosApi, axiosStorageApi } from '../axios';

export default {
  getPlaces: () => (
    axiosApi.get('/api/place/list')
  ),

  getTrains: () => (
    axiosApi.get('/api/train/list')
  ),

  getTrainsByPlaceId: (placeId) => (
    axiosApi.get('/api/train/list', { params: { placeId } })
  ),

  getTrainDetails: (trainId) => (
    axiosApi.get(`/api/train/${trainId}`)
  ),

  getTrainEventList: (userId, placeId, trainId) => (
    axiosApi.get('/api/trainEvent/list', { params: { userId, placeId, trainId } })
  ),

  getQuickList: () => (
    axiosApi.get('/api/quick/list')
  ),

  getQuickById: (quickId) => (
    axiosApi.get('/api/quick', { params: { quickId } })
  ),

  addNewQuick: (quickData) => (
    axiosApi.post('/api/quick', quickData)
  ),

  getQuickImage: (quickId, imageType) => (
    axiosStorageApi.get(`/quick/file/image/${quickId}/${imageType}`)
  ),

  addNewQuickImage: (quickImage) => (
    axiosStorageApi.post('/quick/file/upload', quickImage)
  ),

};
